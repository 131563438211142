import React, { Component } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

class SocialMedia extends Component {

  renderIcons = () => {
    return (
      <div className='social-media__icons'>
        <FacebookShareButton url={this.props.url}>
          <FacebookIcon round size={30} />
        </FacebookShareButton>

        <TwitterShareButton url={this.props.url}>
          <TwitterIcon round size={30} />
        </TwitterShareButton>

        <TelegramShareButton url={this.props.url}>
          <TelegramIcon round size={30} />
        </TelegramShareButton>

        <WhatsappShareButton url={this.props.url}>
          <WhatsappIcon round size={30} />
        </WhatsappShareButton>
      </div>
    )
  }

  render() {
    return(
      <div className='social-media'>
        {this.renderIcons()}
      </div>
    );
  }
}

export default SocialMedia;
