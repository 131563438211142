import React, { Component } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { DropzoneComponent } from "react-dropzone-component";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.model.image
    };
  }

  componentConfig = () => {
    return {
      iconFiletypes: [".jpg", ".png", ".gif"],
      showFiletypeIcon: true,
      postUrl: this.props.uploadPath,
      message: "teste"
    };
  };

  eventHandlers = () => {
    return {
      init: dropzone => {
        this.dropzone = dropzone;
      },
      success: this.uploadSuccess,
      complete: file => this.dropzone.removeFile(file)
    };
  };

  uploadSuccess = (file, response) => {
    this.setState({ image: { ...response } });
  };

  djsConfig = () => {
    let model_name = this.props.model.name;
    let params = {};
    params[`${model_name}_id`] = this.props.id;
    return {
      addRemoveLinks: true,
      params: { ...params }
    };
  };

  removeImage = () => {
    axios(this.props.destroyPath, {
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      method: "DELETE"
    }).then(({ status }) => {
      if (status === 200) {
        this.setState({ image: "" });
      } else {
        alert("Ocorreu um erro!");
      }
    });
  };

  render() {
    const { image } = this.state;
    return (
      <div>
        <div className="admin-gallery__back-button">
          <a
            className="btn btn--secondary btn--tiny"
            href={this.props.back_url}
          >
            Voltar
          </a>
        </div>
        <div className="admin-gallery__item">
          {image && image.id ? (
            <div
              style={{ display: "inline-block" }}
              className="product-card__stock-item__images--large"
            >
              <img src={image.url} />
              <div className="link">
                <a onClick={this.removeImage}> Remover Imagem </a>
              </div>
            </div>
          ) : null}
          <div className="admin-gallery__dropzone-wrapper">
            <DropzoneComponent
              className="admin-gallery__dropzone"
              config={this.componentConfig()}
              eventHandlers={this.eventHandlers()}
              djsConfig={this.djsConfig()}
            >
              <p className="dz-message">
                Clique aqui para adicionar uma imagem
              </p>
            </DropzoneComponent>
          </div>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  image: PropTypes.string,
  mode: PropTypes.string
};
