import React from 'react';
import next from 'images/next.png';
import prev from 'images/prev.png';

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items || [],
      item: props.items[0] || ''
    }
  }

  nextElement = () => {
    const { items , item } = this.state;
    let currentIndex = items.map(el => JSON.stringify(el) )
                            .indexOf(JSON.stringify(item));
    let newItem;
    let nextIndex = currentIndex + 1;
    
    if(nextIndex >= items.length) {
      newItem = items[0];
    } else {
      newItem = items[nextIndex];
    }
    this.setState({ 
      item: newItem
    });
  }

  prevElement = () => {
    const { items , item } = this.state;
    let currentIndex = items.map(el => JSON.stringify(el) )
                            .indexOf(JSON.stringify(item));
    let newItem;
    let nextIndex = currentIndex - 1;

    if(nextIndex <= 0) {
      newItem = items[(items.length -1)];
    } else {
      newItem = items[nextIndex];
    }
    this.setState({ 
      item: newItem
    });
  }

  render() {
    const { item } = this.state;
    return (
      <div style={{backgroundImage: `url(${item.image})` }} className='environment__image'>
        <div className='environment__actions'>
          <div className='environment__image__title'>
            <a href={item.link}>{item.name}</a>  
          </div>
          
          <div className='environment__icons'>
            <div onClick={this.prevElement} className='environment__icon environment__icon--margin'> <img src={prev} /> </div>
            <div onClick={this.nextElement} className='environment__icon'> <img src={next} /> </div>
          </div>
        </div>
      </div>
    );
  }
}
